<template>
    <Modal v-model="showForm" title="导入资产分类" :mask-closable="false">
        <div class="import-data">
            <Button class="download-btn" type="primary" icon="md-cloud-download" @click="downloadFile">下载Excel模板
            </Button>
            <Upload class="upload-btn"
                    ref="upload"
                    :multiple="false"
                    accept=".xlsx,.xls"
                    :format="['xlsx','xls']"
                    :max-size="10*1024"
                    :on-exceeded-size="handExceeded"
                    :before-upload="handleBeforeUpload"
                    :action="verifyAndSaveClassify"
                    :on-success="handleSuccess"
                    :on-error="handleError"
                    :show-upload-list="false"
                    :headers="header">
                <Button type="primary" icon="md-cloud-upload" :loading="loading">导入Excel文件</Button>
            </Upload>
            <div class="import-tips">注意：导入数据前请先下载Excel模版，编辑后请点击导入Excel数据按钮上传</div>
            <div class="import-errors" v-if="importErrorArray.length > 0">
                <Table stripe :columns="importErrorColumns" :data="importErrorArray"></Table>
            </div>
        </div>
        <div slot="footer">
            <Button type="primary" @click="closeModal(false)">确定</Button>
        </div>
    </Modal>
</template>
<script>
import {reqDownloadExcelTemplate} from "../../../../api/exportToExcel-api";
import {reqVerifyAndSaveClassify} from "../../../../api/asset-api"

export default {
    name: "importClassify",
    props: {
        value: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            showForm: this.value,
            header: {},
            loading: false,
            verifyAndSaveClassify: reqVerifyAndSaveClassify,
            importErrorArray: [],
            importErrorColumns: [{
                type: 'index',
                width: 60,
                align: 'center'
            },{
                title: '错误信息',
                key: 'error'
            }]
        }
    },
    methods: {
        downloadFile() {
            let fileName = 'Classify.xlsx';
            reqDownloadExcelTemplate({fileName: fileName}).then(res => {
                const link = document.createElement('a');
                const blob = new Blob([res.data], {type: 'application/vnd.ms-excel'});
                link.style.display = 'none';
                link.href = URL.createObjectURL(blob);
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }).catch(() => {
                this.$Message.error("文件不存在");
            });
        },
        handleBeforeUpload(file) {
            this.loading = true;
        },
        handExceeded() {
            this.$Message.error("文件大小超出10M限制");
            this.loading = false;
        },
        handleSuccess(response) {
            this.loading = false;
            if (response.status === 1) {
                this.$emit('getTreeList');
                this.$emit('getChildListById');
                this.$Message.success("导入成功");
                this.closeModal(false);
            } else {
                this.importErrorArray = [];
                JSON.parse(response.errors).forEach(item => {
                    this.importErrorArray.push({error: item})
                });
                this.$Message.error("导入失败");
            }
        },
        handleError(error, file, fileList) {
            this.loading = false;
            this.$Message.error("导入失败");
        },
        closeModal(val) {
            this.loading = false;
            this.$emit('input', val);
        }
    },
    mounted() {
        let token = sessionStorage.getItem('Authorization');
        this.header = {Authorization: token};
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            //当重新显示增加数据的时候重置整个form表单
            if (val) {
                this.importErrorArray = [];
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    }
}
</script>
