<template>
    <div class="main-content">
        <div class="breadcrumb">
            <Breadcrumb>
                <BreadcrumbItem>资产管理</BreadcrumbItem>
                <BreadcrumbItem>资产设置</BreadcrumbItem>
                <BreadcrumbItem>资产分类</BreadcrumbItem>
            </Breadcrumb>
        </div>
        <div class="filter">
            <Form label-colon inline>
                <FormItem>
                    <Input v-model="keywords" placeholder="输入分类名称、编号" clearable/>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="doSearching">搜索</Button>
                </FormItem>
                <FormItem>
                    <Button type="primary" icon="md-add" @click="handleAddClassify" v-if="permissionButton.add">新增分类</Button>
                </FormItem>
                <FormItem>
                    <Button icon="md-cloud-download" v-if="permissionButton.import" @click="handleImportClassify">批量导入</Button>
                </FormItem>
            </Form>
        </div>
        <Split class="split-pane" min="300" v-model="splitPercent">
            <div slot="left" class="split-pane-left">
                <div class="v-tree">
                    <Tree :data="treeList" :render="renderContent"></Tree>
                </div>
            </div>
            <div slot="right" class="split-pane-right">
                <div class="table">
                    <div class="table-list">
                        <Table stripe :columns="columns" :data="classifyList" :loading="listLoading">
                            <template slot-scope="{ row, index }" slot="action">
                                <a type="text" @click="handleEditClassify(row, index)" v-if="permissionButton.edit" ghost>编辑</a>
                                <Divider type="vertical"/>
                                <a type="error" class="errors" @click="handleDelClassify(row, index)" v-if="permissionButton.del" ghost >删除</a>
                            </template>
                        </Table>
                    </div>
                    <div class="table-footer">
                        <Row>
                            <Col class="total" span="6">共 {{ total }} 条记录 第 {{ pageNum }}/{{ pages }} 页</Col>
                            <Col class="pagenation" span="18">
                                <Page :page-size="pageSize"
                                      :total="total"
                                      :current="pageNum"
                                      :page-size-opts="[15,20,50,100]"
                                      @on-change="changePage"
                                      @on-page-size-change="changPageSize"
                                      show-sizer
                                      show-elevator/>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </Split>

        <AddClassify v-model="addShow" :treeSelectOpts="treeSelectOpts" :pId="parentId" v-on:getTreeList="getTreeList"></AddClassify>
        <EditClassify v-model="editShow" :treeSelectOpts="treeSelectOpts" :data="classify" v-on:getTreeList="getTreeList" v-on:getChildListById="getChildListById"></EditClassify>
        <ImportClassify v-model="importShow" v-on:getTreeList="getTreeList" v-on:getChildListById="getChildListById"></ImportClassify>
    </div>
</template>
<script>
import permission from '@/common/permission';
import {reqGetClassifyTree, reqGetClassifyByFilters, reqGetChildListById, reqDelClassify} from '@/api/asset-api';
import AddClassify from './add.vue';
import EditClassify from './edit.vue';
import ImportClassify from './import.vue';

export default {
    name: 'VTree',
    components:{
        AddClassify,
        EditClassify,
        ImportClassify
    },
    data() {
        return {
            keywords: '',
            permissionButton: {
                add: false,
                import: false,
                edit: false,
                del: false
            },
            splitPercent: 0.2,
            columns: [
                {
                    title: '分类编号',
                    key: 'serialNumber',
                    tooltip: 'ture',
                    width: 130
                },
                {
                    title: '分类名称',
                    key: 'name',
                    minWidth: 170,
                    tooltip: true
                },
                {
                    title: '上级分类',
                    key: 'parentName',
                    minWidth: 170,
                    tooltip: true
                },
                {
                    title: '备注',
                    key: 'memo',
                    minWidth: 150,
                    tooltip: true
                },
                {
                    title: '创建时间',
                    width: 170,
                    render: (h, params) => {
                        return h('span', this.$moment(params.row.createTime).format('YYYY-MM-DD HH:mm:ss'));
                    }
                },
                {
                    title: '操作',
                    slot: 'action',
                    width: 120
                }
            ],
            isSearching: 1,
            total: 0,
            pages: 1,
            pageNum: 1,
            pageSize: 15,
            parentId: '',
            selectedId: '',
            classify: null,
            classifyList: [],
            listLoading: false,
            addShow: false,
            editShow: false,
            importShow: false,
            editState: false, // 编辑状态
            treeSelectOpts: [],
            treeList: [],
            buttonProps: {
                type: 'default',
                size: 'small',
            },
            // 输入框要修改的内容
            inputContent: '',
            // 修改前的TreeNode名称
            oldName: ''
        }
    },
    methods: {
        doSearching() {
            this.isSearching = 1;
            this.pageNum = 1;
            this.getClassifyByFilters();
        },
        getClassifyByFilters() {
            let params = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                keywords: this.keywords
            }
            reqGetClassifyByFilters(params).then((res)=> {
                this.loading = false;
                if (res.data.code === 1) {
                    this.classifyList = res.data.data.records;
                    this.pageNum = res.data.data.current;
                    this.pages = res.data.data.pages;
                    this.total = res.data.data.total;
                } else {
                    this.classifyList = [];
                }
            }).catch(()=>{
                this.classifyList = [];
            })
        },
        //分页点击，切换页面
        changePage(val) {
            this.pageNum = val;
            if (this.isSearching === 1) {
                this.getClassifyByFilters();
            } else {
                this.getChildListById();
            }
        },
        //每页显示的数据条数
        changPageSize(numOfRows) {
            //实时获取当前需要显示的条数
            this.pageSize = numOfRows;
            this.pageNum = 1;
            if (this.isSearching === 1) {
                this.getClassifyByFilters();
            } else {
                this.getChildListById();
            }
        },
        handleAddClassify() {
            this.parentId = null;
            this.addShow = true;
        },
        handleImportClassify() {
            this.importShow = true;
        },
        handleEditClassify(row, index) {
            this.classify = row;
            this.editShow = true;
        },
        handleDelClassify(row, index) {
            this.delClassify(row.id);
        },
        delClassify(classifyId) {
            this.$Modal.confirm({
                title: ' ',
                content: '<p>确认删除该资产分类及全部下级分类？</p>',
                width: '240',
                onOk: () => {
                    this.listLoading = true;
                    reqDelClassify({id: classifyId}).then(res => {
                        this.listLoading = false;
                        if (res.data.code === 1) {
                            this.$Message.success("删除成功");
                            // 删除数据成功同时刷新grid
                            this.getTreeList();
                            this.getChildListById();
                        }
                    }).catch(() => {
                        this.listLoading = false;
                        this.$Message.error("删除失败");
                    });
                }
            })
        },
        // 树渲染逻辑
        renderContent (h, { root, node, data }) {
            return h('span', {
                class: 'common-wrap',
                on:{
                    click:() => {
                        // 点击Tree节点触发
                        if (!data.editState) {
                            this.handleClickTreeNode(root, data, node)
                        }
                    }
                }
            }, [
                h('span', [
                    // 文件前面的文件夹图标
                    h('Icon', {
                        props: {
                            type: `${data.children === undefined || data.children.length === 0 ? 'md-document' :'ios-folder'}`
                        },
                        style: {
                            marginRight: '8px',
                        }
                    }),
                    h(`${ data.editState ? '' : 'span'}`, data.title),
                    h(`${ data.editState ? 'input' : ''}`,
                        {
                            class: 'edit-input',
                            attrs:{
                                value:`${ data.editState ? data.title : ''}`,
                                autofocus: 'true'
                            },
                            style: {
                                // cursor: 'auto' ,
                                // borderRadius: '3px',
                                // border: '1px solid #e5e5e5',
                                // color: '#515a6e'
                            },
                            on:{
                                change: (event) => {
                                    this.inputContent = event.target.value
                                }
                            }
                        }
                    )
                ]),
                // 增删改按钮部分
                h(`${ data.editState ? '' : 'span'}`,
                    {
                        class: 'btnNone'
                    },
                    [
                        // 操作按钮部分
                        // 编辑按钮
                        h('Button', {
                            props: {
                                ...this.buttonProps,
                                icon: 'md-color-filter'
                            },
                            on: {
                                click: () => { this.editTree(data) }
                            }
                        }),
                        // 添加按钮
                        h('Button', {
                            props: {
                                ...this.buttonProps,
                                icon: 'md-add'
                            },
                            on: {
                                click: () => { this.append(data) }
                            }
                        }),
                        // 删除按钮
                        h('Button', {
                            props: {
                                ...this.buttonProps,
                                icon: 'md-remove'
                            },
                            on: {
                                click: () => { this.remove(root, node, data) }
                            }
                        })
                    ]
                ),
                // 确认/取消修改部分
                h(`${ data.editState ? 'span' : ''}`,
                    {
                        style: {
                            marginLeft: '.5rem'
                        }
                    },
                    [
                        // 确认按钮
                        h('Button', {
                            props: {
                                ...this.buttonProps,
                                icon: 'md-checkmark'
                            },
                            style: {
                                border: 0,
                                background: 'rgba(0,0,0,0)',
                                fontSize: '16px',
                                outline: 'none'
                            },
                            on: {
                                click: (event) => {
                                    this.confirmTheChange(data)
                                }
                            }
                        }),
                        // 取消按钮
                        h('Button', {
                            props: {
                                ...this.buttonProps,
                                icon: 'md-close'
                            },
                            style: {
                                border: '0',
                                background: 'rgba(0,0,0,0)',
                                fontSize: '16px',
                                outline: 'none'
                            },
                            on: {
                                click: () => { this.cancelChange(data) }
                            }
                        })
                    ]
                )
            ])
        },
        /**
         * @description: 获取树列表
         * @param {*}
         * @return {*}
         */
        getTreeList() {
            reqGetClassifyTree().then(res => {
                this.treeList = JSON.parse(JSON.stringify(res.data.data));
                this.treeSelectOpts = JSON.parse(JSON.stringify(res.data.data));
            }).catch(()=>{
                this.treeList = [];
                this.treeSelectOpts = [];
            });
        },
        getChildListById() {
            if (!this.selectedId) {
                this.classifyList = [];
            } else {
                let params = {
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    id: this.selectedId
                }
                reqGetChildListById(params).then(res => {
                    this.classifyList = res.data.data.records;
                    this.pageNum = res.data.data.pageNum;
                    this.total = res.data.data.total;
                    this.pages = Math.ceil(res.data.data.total / this.pageSize);
                }).catch(()=>{
                    this.classifyList = [];
                });
            }
        },
        // 控制Tree当前状态函数
        setStates(data){
            let editState=data.editState
            if (editState) {
                this.$set(data, 'editState', false)
            } else {
                this.$set(data, 'editState', true)
            }
        },
        // Tree修改按钮
        editTree(data){
            this.classify = data;
            this.editShow = true;
        },
        // 添加按钮
        append (data) {
            this.parentId = data.id;
            this.addShow = true;
        },
        // 删除按钮
        remove (root, node, data) {
            this.delClassify(data.id);
        },
        // 确认修改树节点
        confirmTheChange(data) {
            if (!this.inputContent) {
                this.$Notice.warning({
                    title: '当前输入有误',
                })
            } else {
                if(this.oldName !== this.inputContent){
                    this.$Modal.confirm({
                        title: '提示',
                        content: `您确定将  “${this.oldName}”  重命名为 “ ${this.inputContent} ” 吗？`,
                        onOk: () => {
                            data.title=this.inputContent
                            this.$Message.info('修改成功')
                        },
                        onCancel: () => {
                            this.$Message.info('取消')
                        }
                    })
                    this.setStates(data)
                } else{
                    this.setStates(data)
                }
            }
        },
        // 取消修改树节点
        cancelChange(data) {
            this.$Notice.info({
                title: '取消修改',
            })
            this.setStates(data)
        },
        // 点击Tree节点触发
        handleClickTreeNode(root, data, node) {
            this.selectedId = data.id;
            this.pageNum = 1;
            this.isSearching = 0;
            this.keywords = '';
            this.getChildListById();
        },
        // js 递归遍历查找对象数组的某一个属性
        filterTableMater(code, arr) {
            for (const item of arr) {
                if (item.id === code) {
                    item.selected = true
                }
                if (item.children && item.children.length) {
                    this.filterTableMater(code, item.children)
                }
            }
        }
    },
    mounted() {
        this.getTreeList();
        this.doSearching();
        let permissionId = this.$route.query.permissionId;
        permission.getPermissionButton(permissionId, this.permissionButton);
    }
}
</script>
