<template>
    <Modal v-model="showForm" title="编辑资产分类" :mask-closable="false">
        <Form ref="editForm" :model="editForm" :rules="editFormRule" label-colon :label-width="90">
            <FormItem label="编号" prop="serialNumber">
                <Input v-model.trim="editForm.serialNumber" :maxlength="30" placeholder="请输入" clearable />
            </FormItem>
            <FormItem label="分类名称" prop="name">
                <Input v-model.trim="editForm.name" :maxlength="30" placeholder="请输入" clearable />
            </FormItem>
            <FormItem label="上级分类" prop="parentId">
                <TreeSelect v-model="editForm.parentId" :options="treeSelectOpts" placeholder="请选择" noOptionsText="暂无数据" noResultsText="没有匹配数据" clearable />
            </FormItem>
            <FormItem label="备注" prop="memo">
                <Input type="textarea" show-word-limit :maxlength="200" v-model.trim="editForm.memo"
                       :autosize="{minRows: 3, maxRows: 5}" placeholder="请输入"/>
            </FormItem>
        </Form>
        <div slot="footer">
            <Button type="default" @click="closeModal(false)">取消</Button>
            <Button type="primary" :loading="loading" @click="handleSubmit">确定</Button>
        </div>
    </Modal>
</template>
<script>
import {reqEditClassify} from '@/api/asset-api';
import {getChildrenById} from "../../../../assets/js/treeFun";
import TreeSelect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
    name: "editClassify",
    components: {
        TreeSelect
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        data: {},
        treeSelectOpts: []
    },
    data() {
        return {
            showForm: this.value,
            loading: false,
            invalidParentId: '',
            editForm: {
                id: '',
                serialNumber: '',
                name: '',
                parentId: '',
                memo: ''
            },
            editFormRule: {
                serialNumber: [
                    {required: true, message: '请输入编号', trigger: 'blur'}
                ],
                name: [
                    {required: true, message: '请输入分类名称', trigger: 'blur'}
                ],
                parentId: [{
                    trigger: 'blur',
                    validator: (rule, value, callback) => {
                        if (value) {
                            let childrenIds = [];
                            childrenIds = [...childrenIds, ...getChildrenById(this.treeSelectOpts, this.invalidParentId, [])];
                            if (childrenIds.includes(value)) {
                                return callback(new Error("不能选择本级分类及其下级分类"));
                            }
                        }
                        return callback();
                    }
                }]
            }
        }
    },
    methods: {
        handleSubmit() {
            this.$refs['editForm'].validate((valid)=> {
                if (valid) {
                    this.loading = true;
                    delete this.editForm['createTime'];
                    reqEditClassify(this.editForm).then((res)=> {
                        this.loading = false;
                        if (res.data.code === 1) {
                            this.closeModal(false);
                            this.$emit('getTreeList');
                            this.$emit('getChildListById');
                            this.$Message.success('编辑成功');
                        } else if (res.data.code === -1) {
                            this.$Message.error('分类编号已存在');
                        } else if (res.data.code === -2) {
                            this.$Message.error('分类名称已存在');
                        } else {
                            this.$Message.error('编辑失败');
                        }
                    }).catch(()=>{
                        this.loading = false;
                        this.$Message.error('编辑失败');
                    })
                }
            })
        },
        closeModal(val) {
            this.$emit('input', val);
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            if (val) {
                this.invalidParentId = this.data.id;
                Object.assign(this.editForm, this.data);
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    }
}
</script>
