<template>
    <Modal v-model="showForm" title="新增资产分类" :mask-closable="false">
        <Form ref="addForm" :model="addForm" :rules="addFormRule" label-colon :label-width="90">
            <FormItem label="编号" prop="serialNumber">
                <Input v-model.trim="addForm.serialNumber" :maxlength="30" placeholder="请输入" clearable />
            </FormItem>
            <FormItem label="分类名称" prop="name">
                <Input v-model.trim="addForm.name" :maxlength="30" placeholder="请输入" clearable />
            </FormItem>
            <FormItem label="上级分类" prop="parentId">
                <TreeSelect v-model="addForm.parentId" :options="treeSelectOpts" placeholder="请选择" noOptionsText="暂无数据" noResultsText="没有匹配数据" clearable />
            </FormItem>
            <FormItem label="备注" prop="memo">
                <Input type="textarea" show-word-limit :maxlength="200" v-model.trim="addForm.memo"
                       :autosize="{minRows: 3, maxRows: 5}" placeholder="请输入"/>
            </FormItem>
        </Form>
        <div slot="footer">
            <Button type="default" @click="closeModal(false)">取消</Button>
            <Button type="primary" :loading="loading" @click="handleSubmit">确定</Button>
        </div>
    </Modal>
</template>
<script>
import {reqAddClassify} from '@/api/asset-api';
import TreeSelect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
    name: "addClassify",
    components: {
        TreeSelect
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        treeSelectOpts: [],
        pId: null
    },
    data() {
        return {
            showForm: this.value,
            loading: false,
            addForm: {
                serialNumber: '',
                name: '',
                parentId: null,
                memo: ''
            },
            addFormRule: {
                serialNumber: [
                    {required: true, message: '请输入编号', trigger: 'blur'}
                ],
                name: [
                    {required: true, message: '请输入分类名称', trigger: 'blur'}
                ]
            }
        }
    },
    methods: {
        handleSubmit() {
            this.$refs['addForm'].validate((valid)=> {
                if (valid) {
                    this.loading = true;
                    reqAddClassify(this.addForm).then((res)=> {
                        this.loading = false;
                        if (res.data.code === 1) {
                            this.closeModal(false);
                            this.$emit('getTreeList');
                            this.$Message.success('添加成功');
                        } else if (res.data.code === -1) {
                            this.$Message.error('分类编号已存在');
                        } else if (res.data.code === -2) {
                            this.$Message.error('分类名称已存在');
                        } else {
                            this.$Message.error('添加失败');
                        }
                    }).catch(()=>{
                        this.loading = false;
                        this.$Message.error('添加失败');
                    })
                }
            })
        },
        closeModal(val) {
            this.$emit('input', val);
        }
    },
    watch: {
        value(val) {
            this.showForm = val;
        },
        showForm(val) {
            //当重新显示增加数据的时候重置整个form表单
            if (val) {
                this.$refs['addForm'].resetFields();
                this.addForm.parentId = this.pId;
            } else {// 反之则关闭页面
                this.closeModal(val);
            }
        }
    }
}
</script>
